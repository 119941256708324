const routes = {
  login: '/login',
  otpverification: '/otpverification',
  emailverification: '/emailverification',
  signup: '/signup',
  onboarding: '/onBoarding',
  forgotPassword: '/forgotPassword',
  changePassword: '/changePassword',
  restaurant: '/restaurant',
  users:"/users",
  orders:"/orders",
  particularRestaurant: "/restaurant/:restaurantId",
  foodSpot: "/foodSpot"
 
};

export default routes;
