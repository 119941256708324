import axios from 'axios';
import { BACKEND_URL } from '../Helpers/constant';

export const login = async (payload:any) => {
  try {
    const res = await axios.post(`${BACKEND_URL}/auth/admin_login`,payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getRestautantDetails = async () => {
  try {
    const res = await axios.get(`${BACKEND_URL}/restaurant/get_all_restaurant`)
    return res && res.data ? res.data : res;
  } catch(error) {
    return error;
  }
}

export const getUserDetails = async () => {
  try {
    const res = await axios.get(`${BACKEND_URL}/user/get_all_user`)
    return res && res.data ? res.data : res;
  } catch(error) {
    return error;
  }
}

export const getOrderDetailsViaRestautrantId = async (restaurant_id: any) => {
  try {
    const res = await axios.get(`${BACKEND_URL}/restaurant/orders?restaurant_id=${restaurant_id}`)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const getRestaurantItems = async (restaurant_id: any) => {
  try {
    const res = await axios.get(`${BACKEND_URL}/restaurant/get_items?restaurant_id=${restaurant_id}`)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const getMealsDetailsViaRestautrantId = async (restaurant_id: any) => {
  try {
    const res = await axios.get(`${BACKEND_URL}/restaurant/current_item?restaurant_id=${restaurant_id}`)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const getDeliveryExecutiveDetailsViaRestaurantId = async (restaurant_id: any) => {
  try {
    const res = await axios.get(`${BACKEND_URL}/user/get_restaurant_user?restaurant_id=${restaurant_id}`)
    return res && res.data ? res.data : res;
  } catch(error){
    return error;
  }
}

export const createRestaurantUser = async(payload: any) => {
  try {
    const res = await axios.post(`${BACKEND_URL}/user/create_user`, payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const updateRestaurantStatus = async(payload: any) => {
  try {
    const res = await axios.patch(`${BACKEND_URL}/restaurant/update_restaurant`, payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const addNewRestaurant = async(payload: any) => {
  try {
    const res = await axios.post(`${BACKEND_URL}/restaurant/create_restaurant`, payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const createUser = async(payload: any) => {
  try {
    const res = await axios.post(`${BACKEND_URL}/user/create_user`, payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const getFoodSpotDetails = async () => {
  try {
    const res = await axios.get(`${BACKEND_URL}/food_spot/get_food_spot`)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}


export const createFoodSpot = async (payload: any) => {
  try {
    const res = await axios.post(`${BACKEND_URL}/food_spot/create_food_spot`, payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

