import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from '../../Components/AppLayout';
import AuthLayout from '../../Components/AuthLayout';
import { useAppContext } from '../../Helpers/Contexts/appContext';
import { PrivateRoute, PublicRoute } from '../../Utils/helperFunctions';
import routes from '../../Utils/routes';
import FoodSpot from '../FoodSpot';

const Login = React.lazy(() => import('../Auth/Login/index'));
const Restaurant = React.lazy(() => import('../Restaurant'));
const Users = React.lazy(() => import('../Users'));
const Orders = React.lazy(() => import('../Restaurant/Order'));
const ParticularRestaurant = React.lazy(() => import('../Restaurant/ParticularRestaurant'));

const MainLayout: React.FunctionComponent = () => {
  const {
    state: { config },
  } = useAppContext();

  if (config.apiBaseUrl !== '') {
    return null; // Or some loading component
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to={routes.login} />} />

      <Route
        path={routes.login}
        element={
          <PublicRoute>
            <AuthLayout>
              <Login />
            </AuthLayout>
          </PublicRoute>
        }
      />

      {[
        { path: routes.restaurant, Component: Restaurant },
        { path: routes.users, Component: Users },
        { path: routes.orders, Component: Orders },
        { path: routes.particularRestaurant, Component: ParticularRestaurant },
        { path: routes.foodSpot, Component: FoodSpot },
      ].map(({ path, Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateRoute>
              <AppLayout>
                <Component />
              </AppLayout>
            </PrivateRoute>
          }
        />
      ))}
    </Routes>
  );
};

export default MainLayout;
