import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, FormControl, InputLabel, TextField as MuiTextField, InputAdornment } from '@mui/material';
import { getFoodSpotDetails, createFoodSpot } from '../../Utils/apiService';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

interface FoodSpotDetail {
  id: number;
  spot_name: string;
  food_beverages: string;
  locality: string;
  opens_at: string;
  closes_at: string;
  geo_location: string;
  crowd_popularity: string;
  veg_nonveg: string;
  street_establishment: string;
  avg_price: string;
}

interface FoodSpotFormData {
  spot_name: string;
  food_beverages: string;
  locality: string;
  opens_at: string;
  closes_at: string;
  spot_image: string;
  geo_location: string;
  crowd_popularity: string;
  veg_nonveg: string;
  street_establishment: string;
  avg_price: number;
}

const initialFormData: FoodSpotFormData = {
  spot_name: "",
  food_beverages: "",
  locality: "",
  opens_at: "",
  closes_at: "",
  spot_image: "",
  geo_location: "",
  crowd_popularity: "Medium",
  veg_nonveg: "Both",
  street_establishment: "Restaurant",
  avg_price: 0,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

function createData(
  id: number,
  spot_name: string,
  food_beverages: string,
  locality: string,
  opens_at: string,
  closes_at: string,
  geo_location: string,
  crowd_popularity: string,
  veg_nonveg: string,
  street_establishment: string,
  avg_price: string
): FoodSpotDetail {
  return { id, spot_name, food_beverages, locality, opens_at, closes_at, geo_location, crowd_popularity, veg_nonveg, street_establishment, avg_price };
}

const FoodSpot = () => {
  const [rows, setRows] = React.useState<FoodSpotDetail[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formData, setFormData] = React.useState<FoodSpotFormData>(initialFormData);
  const [searchQuery, setSearchQuery] = React.useState<string>('');

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const foodSpotDetails = await getFoodSpotDetails();
        console.log(foodSpotDetails, "foodSpotDetails");

        const fetchedRows = foodSpotDetails.data.map((detail: any) =>
          createData(detail.id, detail.spot_name, detail.food_beverages, detail.locality, detail.opens_at, detail.closes_at, detail.geo_location, detail.crowd_popularity, detail.veg_nonveg, detail.street_establishment, detail.avg_price)
        );

        console.log(fetchedRows, "fetchedRows");

        setRows(fetchedRows);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching food spot details:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCreateFoodSpot = async () => {
    try {
    const foodSpotArray = [formData];
      const response = await createFoodSpot(foodSpotArray);
      setOpenDialog(false);
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error creating food spot:', error);
    }
  };

  const handleInputChange:any = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'avg_price' ? parseFloat(value) : value,
    }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      value !== null && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setFormData(initialFormData);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4 }}>
        Food Spot Management
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
        <MuiTextField
          variant="outlined"
          placeholder="Search food spots..."
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialogOpen}
          startIcon={<AddIcon />}
        >
          Create New Food Spot
        </Button>
      </Box>
      <TableContainer component={Paper} elevation={3} sx={{ maxHeight: 440, mb: 4 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="food spot details table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Food & Beverages</StyledTableCell>
              <StyledTableCell align="left">Locality</StyledTableCell>
              <StyledTableCell align="left">Opens At</StyledTableCell>
              <StyledTableCell align="left">Closes At</StyledTableCell>
              <StyledTableCell align="left">Popularity</StyledTableCell>
              <StyledTableCell align="left">Veg/Non-veg</StyledTableCell>
              <StyledTableCell align="left">Establishment</StyledTableCell>
              <StyledTableCell align="left">Avg Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
                  transition: 'background-color 0.3s',
                }}
              >
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell align="left">{row.spot_name}</TableCell>
                <TableCell align="left">{row.food_beverages}</TableCell>
                <TableCell align="left">{row.locality}</TableCell>
                <TableCell align="left">{row.opens_at}</TableCell>
                <TableCell align="left">{row.closes_at}</TableCell>
                <TableCell align="left">{row.crowd_popularity}</TableCell>
                <TableCell align="left">{row.veg_nonveg}</TableCell>
                <TableCell align="left">{row.street_establishment}</TableCell>
                <TableCell align="left">{row.avg_price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>Create New Food Spot</DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <TextField
            autoFocus
            margin="dense"
            name="spot_name"
            label="Spot Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.spot_name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="food_beverages"
            label="Food & Beverages"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.food_beverages}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="locality"
            label="Locality"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.locality}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="opens_at"
            label="Opens At"
            type="time"
            fullWidth
            variant="outlined"
            value={formData.opens_at}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            name="closes_at"
            label="Closes At"
            type="time"
            fullWidth
            variant="outlined"
            value={formData.closes_at}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            name="spot_image"
            label="Spot Image URL"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.spot_image}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="geo_location"
            label="Geo Location"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.geo_location}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="crowd-popularity-label">Crowd Popularity</InputLabel>
            <Select
              labelId="crowd-popularity-label"
              name="crowd_popularity"
              value={formData.crowd_popularity}
              onChange={handleInputChange}
              label="Crowd Popularity"
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="veg-nonveg-label">Veg/Non-veg</InputLabel>
            <Select
              labelId="veg-nonveg-label"
              name="veg_nonveg"
              value={formData.veg_nonveg}
              onChange={handleInputChange}
              label="Veg/Non-veg"
            >
              <MenuItem value="Veg">Veg</MenuItem>
              <MenuItem value="Non-veg">Non-veg</MenuItem>
              <MenuItem value="Both">Both</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="street-establishment-label">Street Establishment</InputLabel>
            <Select
              labelId="street-establishment-label"
              name="street_establishment"
              value={formData.street_establishment}
              onChange={handleInputChange}
              label="Street Establishment"
            >
              <MenuItem value="Restaurant">Restaurant</MenuItem>
              <MenuItem value="Food Truck">Food Truck</MenuItem>
              <MenuItem value="Street Vendor">Street Vendor</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="avg_price"
            label="Average Price"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.avg_price}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleDialogClose} variant="outlined">Cancel</Button>
          <Button onClick={handleCreateFoodSpot} variant="contained" color="primary">Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FoodSpot;